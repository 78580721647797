export function validatePhoneNumber(phoneNumber) {
    const numberRegex = /^(?:\+88|88)?(01[3-9]\d{8})$/;
  return numberRegex.test(phoneNumber);
}
// prevent number input field to input e or E and + or -
export function handleKeyDown (event) {
  if (event.key === 'e' || event.key === 'E' || event.key === '+' || event.key === '-') {
    event.preventDefault();
  }
};

// password Validation
export const validatePassword = (password) => {
  const minLength = 8;
  const patterns = {
    minLength: password.length >= minLength,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    numbers: /[0-9]/.test(password),
    specialChar: /[@#$!%*?&]/.test(password),
  };

  let messages = [];

  if (!patterns.minLength) messages.push(" 8 characters long");
  if (!patterns.uppercase) messages.push(" one uppercase letter (A-Z)");
  if (!patterns.lowercase) messages.push(" one lowercase letter (a-z)");
  if (!patterns.numbers) messages.push(" one number (0-9)");
  if (!patterns.specialChar) messages.push(" one special character (@#$!%*?&)");

  const combinedMessage = messages.length ? `Password must be at least ${messages.join(", ")}.` : "";

  return { status: patterns, messages: combinedMessage };
};

// fetch user's IP address
export const fetchIpAddress = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  } catch (err) {
    console.error("Failed to fetch IP address", err);
    return '127.0.0.1';
  }
};

export const defaultMobileNumber = (mobileNumber) =>{
  if(mobileNumber && mobileNumber.length > 0){
    if(mobileNumber[0] === '0'){
      let formattedNumber = mobileNumber.substring(1)
      return formattedNumber;
    }else{
      return mobileNumber;
    }
  }else{
    return ''
  }
}

  
