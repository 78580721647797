import React, { useEffect, useState, useRef, useContext } from 'react'
import { postCandidateExperienceByID, deleteCandidateExperienceByID, updateCandidateExperienceByID } from '../../../../../fetchAPI';
import Alert from '@mui/material/Alert';
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal';
import Select from 'react-select'
import JoditEditor from 'jodit-react';
import AddButton from './component/AddButton';
import customStyles from './CandidateUpdateProfile.module.css'
import CandidateUpdateModal from './component/CandidateUpdateModal';
import SingleExpBlock from './component/SingleExpBlock';
import EditSVG from './component/EditSVG';
import DeleteSVG from './component/DeleteSVG';
import { useAlert } from '../../../../context/AlertContext';
import SkillAddForm from './component/SkillAddForm';
import { DropdownContext } from '../../../../context/DropdownContext';
import { WORK_TYPE_LIST } from '../../../../../constants';
import { Box, CircularProgress } from '@mui/material';
import RequiredAsterisk from './component/RequiredAsterisk';
import { useLocation } from 'react-router-dom';

function UpdateEmployment(props) {

    const { types, departments, industries } = useContext(DropdownContext);

    const [experienceData, setExperienceData] = useState({ currently_working: 0 })
    const [updateExperienceData, setUpdateExperienceData] = useState({})

    const [loader, setLoader] = useState(false)

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);

    const alertContext = useAlert();

    const skillAddFormContainer = useRef();
    const skillAddFormContainerinUpdateExpForm = useRef();
    const { search } = useLocation();

	useEffect(() => {
	  const scrollToHash = () => {
		const params = new URLSearchParams(search);
		const scrollTo = params.get('scrollTo');
		if (scrollTo) {
		  setTimeout(() => {
			const element = document.getElementById(scrollTo);
			if (element) {
				const offset = element.offsetTop - 220;
				window.scrollTo({ top: offset, behavior: 'smooth' });
			}
		  }, 100);
		}
	  };
  
	  scrollToHash();
	  window.addEventListener('popstate', scrollToHash);

	  return () => {
		window.removeEventListener('popstate', scrollToHash);
	  };
	}, [search]);

    const handleOpen = (itemId) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const enableUpdate = (item) => {
        setUpdateExperienceData({
            ...updateExperienceData,
            id: item.id,
            organization_name: item.organization_name,
            role: item.role,
            start_date: item.start_date,
            end_date: item.end_date,
            organization_desc: item.organization_desc,
            currently_working: item.currently_working,
            technology: item.technology?.map(val => val.id).toString(),
            candidate_type_id: item.candidate_type_id,
            industry_id: item.industry_id,
            industryLabel: item.industry?.name,
            department_id: item.department_id,
            departmentLabel: item.department?.name,
            company_location: item.company_location,
            candidate_work_type: item.candidate_work_type,
        })

    }

    const handleUpdateChange = (name) => (event) => {
        setUpdateExperienceData({
            ...updateExperienceData,
            [name]: event.target.value
        })
    }

    const handleChange = (name) => (event) => {
        setExperienceData({ ...experienceData, [name]: event.target.value })
    }

    const handleCheckBox = (name) => (event) => {
        setExperienceData({ ...experienceData, [name]: +(event.target.checked) })
    }

    const handleUpdateCheckBox = (name) => (event) => {
        setUpdateExperienceData({ ...updateExperienceData, [name]: +(event.target.checked) })
    }

    const handleChangeSelect = (name) => (event) => {
        setExperienceData({ ...experienceData, [name]: event.value })
    }

    const handleUpdateChangeSelect = (name) => (event) => {
        setUpdateExperienceData({ ...updateExperienceData, [name]: event.value })
    }

    let errorsObj = { role: '', organization_name: '', start_date: '', end_date: '', desc: '', dateValid: '', currently_working: '' };
    const [errors, setErrors] = useState('')
    const insertCandidateExperience = (candId, body) => {

        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.role) {
            errorObj.role = 'Designation is Required';
            error = true;
        }
        if (!body.organization_name) {
            errorObj.organization_name = 'Organization Name is Required';
            error = true;
        }
        if (!body.start_date) {
            errorObj.start_date = 'Start Date is Required';
            error = true;
        }
        if (body.currently_working === 0 && !body.end_date) {
            errorObj.end_date = 'End Date is Required';
            error = true;
        }
        if (body.end_date < body.start_date) {
            errorObj.dateValid = 'End Date cannot be before Start Date';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            // Do not call the Post API
            setLoader(false);
        }
        else {
            setAddModalOpen(false);
            setExperienceData({});
            postCandidateExperienceByID(candId, body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    setSkillMultiValue([])
                    setSkillUpdateMultiVaue([])
                    props.renderState()
                    setLoader(false);
                }
                else {
                    console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }

    const [skillMultiValue, setSkillMultiValue] = useState([])
    const [skillUpdateMultiValue, setSkillUpdateMultiVaue] = useState([])

    useEffect(() => {
        setUpdateExperienceData({ ...updateExperienceData, 'technology': skillUpdateMultiValue?.map(val => val.value).toString() })
    }, [skillUpdateMultiValue])

    useEffect(() => {
        setExperienceData({ ...experienceData, 'technology': skillMultiValue?.map(val => val.value).toString() })
    }, [skillMultiValue])

    const [updateErrors, setUpdateErrors] = useState('')
    const updateCandidateExperience = (candId, body) => {

        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.role) {
            errorObj.role = 'Designation is Required';
            error = true;
        }
        if (!body.organization_name) {
            errorObj.organization_name = 'Organization Name is Required';
            error = true;
        }
        if (!body.start_date) {
            errorObj.start_date = 'Start Date is Required';
            error = true;
        }
        if (body.currently_working === 0 && body.end_date === '') {
            errorObj.end_date = 'End Date is Required';
            error = true;
        }
        if (body.end_date && body.end_date < body.start_date) {
            errorObj.dateValid = 'End Date cannot be before Start Date';
            error = true;
        }
        setUpdateErrors(errorObj);
        if (error) {
            // Do not call the Post API here
            setLoader(false);
        }
        else {
            setUpdateModalOpen(false);
            setUpdateExperienceData({});
            updateCandidateExperienceByID(candId, body.id, body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    setSkillMultiValue([])
                    setSkillUpdateMultiVaue([])
                    setLoader(false);
                    props.renderState()
                }
                else {
                    console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }

    const [deleteItemId, setDeleteItemId] = useState('')

    const deleteCandidateExperience = (candId, experienceId, index) => {
        setOpenDeleteModal(false)
        setLoader(true)
        deleteCandidateExperienceByID(candId, experienceId).then((response) => {
            if (response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)

                props.experiences.splice(index, 1);
                props.renderState()
                setLoader(false)
            }
            else {
                console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)

                props.renderState()
            }
        })
    }

    const handleTechnology = (name) => (event) => {
        setSkillMultiValue(event)
    }

    const handleUpdateTechnology = (name) => (event) => {
        setSkillUpdateMultiVaue(event)
    }

    // const [addSkillInfo, setAddSkillInfo] = useState('')
    const [addSkillModal, setAddSkillModal] = useState(false)
    const [addSkillModalUpdateExpForm, setAddSkillModalUpdateExpForm] = useState(false)

    return (
        <div id='employment_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>Work Experience</div>
                        <AddButton title="Work Experience" onClickHandler={() => setAddModalOpen(true)} />
                    </div>
                </div>
                <div className='card-body'>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            props.experiences?.map((item, index) => {
                                return (
                                    <div key={item.id}>
                                        {index > 0 ? <hr /> : ''}
                                        <div className={customStyles.singleExp} >
                                            <div className={customStyles.expTitle}>{item.role}</div>
                                            <div className={customStyles.actionContainer} style={{ marginLeft: '10px' }}>
                                                <span style={{ height: '32px' }} onClick={() => {
                                                    enableUpdate(item);
                                                    setSkillUpdateMultiVaue(item.technology?.map(item => {
                                                        return (
                                                            { label: item.skill_name, value: item.id }
                                                        )
                                                    }));
                                                    setUpdateModalOpen(true);
                                                }}>
                                                    <EditSVG />
                                                </span>
                                                <span style={{ height: '32px' }} onClick={() => handleOpen(item.id)}>
                                                    <DeleteSVG />
                                                </span>
                                            </div>
                                        </div>
                                        <SingleExpBlock item={item} />
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
            <CandidateUpdateModal form="candidateExpAddForm" open={addModalOpen} onClose={() => [setErrors({}), setAddModalOpen(false), setExperienceData({})]} title='New Experience'>
                <div>
                    <div className='card-body' >
                        <form id='candidateExpAddForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); insertCandidateExperience(props.candId, experienceData); }}>
                            <div className={`row text-left`}>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Designation <RequiredAsterisk /></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('role')} placeholder='Type your Designation' type='text' />
                                        {errors.role && <Alert severity="error">{errors.role}</Alert>}
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6 ">
                                    <div className="form-group">
                                        <label>Employment type</label>
                                        <select onChange={handleChange('candidate_type_id')} className='form-control' style={{ borderRadius: '5px' }}>
                                            <option value=''>Select Type</option>
                                            {
                                                types.map((item) => {
                                                    return (<option key={item.id} value={item.id}>{item.name}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Company Name <RequiredAsterisk /></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('organization_name')} placeholder='Type your Organization Name' type='text' />
                                        {errors.organization_name && <Alert severity="error">{errors.organization_name}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <div className="form-group">
                                        <label>Industry</label>
                                        <Select
                                            options={industries?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleChangeSelect('industry_id')}
                                            placeholder='Add Industry'
                                        />
                                    </div>
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <div className="form-group">
                                        <label>Department </label>
                                        <Select
                                            options={departments?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleChangeSelect('department_id')}
                                            placeholder='Add Department'
                                        />
                                    </div>
                                </div>

                                <div className='col-md-3 col-lg-3'>
                                    <div className='form-group'>
                                        <label>Start Date <RequiredAsterisk /></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('start_date')} placeholder='Start Date' type='date' />
                                        {errors.start_date && <Alert severity="error">{errors.start_date}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-3 col-lg-3'>
                                    <div className='form-group'>
                                        <label>End Date {!experienceData.currently_working ? <RequiredAsterisk/> : ''}</label>
                                        <input className='form-control atb-input-box' disabled={experienceData.currently_working == 1 ? true : false} onChange={handleChange('end_date')} placeholder='End Date' type='date' />
                                        {errors.end_date && <Alert severity="error">{errors.end_date}</Alert>}
                                        {errors.dateValid && <Alert severity="error">{errors.dateValid}</Alert>}
                                        <input className='m-t10' type='checkbox' onChange={handleCheckBox('currently_working')} /> Currently working here
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Company Location</label>
                                        <input className='form-control atb-input-box' onChange={handleChange('company_location')} placeholder='Type location' type='text' />
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6 ">
                                    <div className="form-group">
                                        <label>Candidate work Type</label>
                                        <select onChange={handleChange('candidate_work_type')} className='form-control' style={{ borderRadius: '5px' }}>
                                            <option value=''>Select Work Type</option>
                                            {
                                                WORK_TYPE_LIST.map((item) => {
                                                    return (<option key={item.id} value={item.value}>{item.label}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='col-md-12 col-lg-12'>
                                    <div className='form-group'>
                                        <label>Job Responsibilities </label>
                                        <JoditEditor
                                            value={experienceData.organization_desc || ''}
                                            buttons={[
                                                'bold', '|',
                                                'italic', '|',
                                                'ul', '|', 'underline', '|']}
                                            className='m-t10'
                                            onChange={(content) => setExperienceData({ ...experienceData, organization_desc: content })}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 mt-3'>
                                    <label>Area of Expertise / Skills</label>

                                </div>
                                <div className='col-10' style={{ zIndex: '20' }}>
                                    <Select
                                        placeholder='Select Skill'
                                        options={props.skills?.map(item => {
                                            return (
                                                { label: item.skill_name, value: item.id }
                                            )
                                        })
                                        }
                                        value={skillMultiValue}
                                        // isDisabled={true}
                                        isMulti
                                        onChange={handleTechnology('technology')}
                                        className='skill-update-container m-t10'
                                        classNamePrefix='skill-update'
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderRadius: '5px !important',

                                            }),
                                        }}
                                    />
                                </div>
                                <div className='col-2'>
                                    <img className='plus-skill mt-3' src='/v2/icons/plus.png'
                                        onClick={() => [setAddSkillModal((prev) => !prev), setTimeout(() => skillAddFormContainer.current?.scrollIntoView({ behavior: "smooth" }), 200)]}
                                    />
                                </div>
                            </div>

                        </form>
                        <div ref={skillAddFormContainer}>
                            <SkillAddForm
                                open={addSkillModal}
                                candId={props.candId}
                                skillMultiValue={skillMultiValue}
                                setSkillMultiValue={setSkillMultiValue}
                                renderState={props.renderState}
                            />
                        </div>
                    </div>
                </div>
            </CandidateUpdateModal>

            <CandidateUpdateModal form="candidateExpUpdateForm" open={updateModalOpen} onClose={() => [setUpdateErrors({}), setUpdateModalOpen(false), setUpdateExperienceData({})]} title='Update Experience'>
                <div>
                    <div className='card-body'>
                        <form id='candidateExpUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateCandidateExperience(props.candId, updateExperienceData); }}>
                            <div className='row'>

                                <div className='col-md-6 col-lg-6'>
                                    <label>Designation <RequiredAsterisk /></label>
                                    <input
                                        onChange={handleUpdateChange('role')}
                                        className='form-control atb-input-box m-b10' type='text' value={updateExperienceData.role} />
                                    {updateErrors.role && <Alert severity="error">{updateErrors.role}</Alert>}
                                </div>

                                <div className="col-md-6 col-lg-6 ">
                                    <div className="form-group">
                                        <label>Employment type</label>
                                        <select value={updateExperienceData.candidate_type_id} onChange={handleUpdateChange('candidate_type_id')} className='form-control' style={{ borderRadius: '5px' }}>
                                            <option value=''>Select Type</option>
                                            {
                                                types.map((item) => {
                                                    return (<option selected={updateExperienceData.candidate_type_id == item.id ? 'selected' : ''} key={item.id} value={item.id}>{item.name}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <label>Company Name <RequiredAsterisk /></label>
                                    <input
                                        onChange={handleUpdateChange('organization_name')}
                                        className='form-control atb-input-box m-b10' type='text' value={updateExperienceData.organization_name} />
                                    {updateErrors.organization_name && <Alert seveSrity="error">{updateErrors.organization_name}</Alert>}
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <div className="form-group">
                                        <label>Industry</label>
                                        <Select
                                            defaultValue={{ label: updateExperienceData.industryLabel, value: updateExperienceData.industry_id }}
                                            options={industries?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleUpdateChangeSelect('industry_id')}
                                            placeholder='Add Industry'
                                        />
                                    </div>
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <div className="form-group">
                                        <label>Department </label>
                                        <Select
                                            defaultValue={{ label: updateExperienceData.departmentLabel, value: updateExperienceData.department_id }}
                                            options={departments?.map((item) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleUpdateChangeSelect('department_id')}
                                            placeholder='Add Department'
                                        />
                                    </div>
                                </div>


                                <div className='col-md-3 col-lg-3'>
                                    <label>Start Date <RequiredAsterisk /></label>
                                    <input
                                        onChange={handleUpdateChange('start_date')}
                                        className='form-control atb-input-box m-b10' type='date' value={updateExperienceData.start_date} />
                                    {updateErrors.start_date && <Alert severity="error">{updateErrors.start_date}</Alert>}
                                </div>

                                <div className='col-md-3 col-lg-3'>
                                    <label>End Date {!updateExperienceData.currently_working ? <RequiredAsterisk/> : ''}</label>
                                    <input onChange={handleUpdateChange('end_date')}
                                        className='form-control atb-input-box m-b10' type='date' value={updateExperienceData.end_date} disabled={updateExperienceData.currently_working == 1 ? true : false}/>
                                    {updateErrors.end_date && <Alert severity="error">{updateErrors.end_date}</Alert>}
                                    {updateErrors.dateValid && <Alert severity="error">{updateErrors.dateValid}</Alert>}
                                    <input className='m-t10' type='checkbox' onChange={handleUpdateCheckBox('currently_working')} checked={updateExperienceData.currently_working == 1 ? true:false}/> Currently working here
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Company Location</label>
                                        <input value={updateExperienceData.company_location} className='form-control atb-input-box' onChange={handleUpdateChange('company_location')} placeholder='Type location' type='text' />
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6 ">
                                    <div className="form-group">
                                        <label>Candidate work Type</label>
                                        <select value={updateExperienceData.candidate_work_type} onChange={handleUpdateChange('candidate_work_type')} className='form-control' style={{ borderRadius: '5px' }}>
                                            <option value=''>Select Work Type</option>
                                            {
                                                WORK_TYPE_LIST.map((item) => {
                                                    return (<option selected={updateExperienceData.candidate_work_type == item.value ? 'selected' : ''} key={item.id} value={item.value}>{item.label}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='col-md-12 col-lg-12 m-t10'>
                                    <label>Job Responsibilities </label>
                                    <JoditEditor
                                        value={updateExperienceData.organization_desc || ''}
                                        buttons={[
                                            'bold', '|',
                                            'italic', '|',
                                            'ul', '|', 'underline', '|']}
                                        className='m-t10'
                                        onChange={(content) => setUpdateExperienceData({ ...updateExperienceData, organization_desc: content })}
                                    />
                                </div>

                                <div className='col-12 mt-3'>
                                    <label>Area of Expertise / Skills</label>
                                </div>
                                <div className='col-11' style={{ zIndex: '20' }}>
                                    <Select
                                        placeholder='Select Skill'
                                        value={skillUpdateMultiValue}
                                        options={props.skills?.map(item => {
                                            return (
                                                { label: item.skill_name, value: item.id }
                                            )
                                        })
                                        }
                                        isMulti
                                        onChange={handleUpdateTechnology('technology')}
                                        className='skill-update-container m-t10'
                                        classNamePrefix='skill-update'
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                borderRadius: '5px !important',
                                                height: '44px'
                                            }),
                                        }}
                                    />
                                </div>
                                <div className='col-1'>
                                    <img className='plus-skill mt-3' src='/v2/icons/plus.png'
                                        onClick={() => [setAddSkillModalUpdateExpForm((prev) => !prev), setTimeout(() => skillAddFormContainerinUpdateExpForm.current?.scrollIntoView({ behavior: "smooth" }), 200)]}
                                    />
                                </div>
                            </div>
                        </form>
                        <div ref={skillAddFormContainerinUpdateExpForm}>
                            <SkillAddForm
                                open={addSkillModalUpdateExpForm}
                                candId={props.candId}
                                skillUpdateMultiValue={skillUpdateMultiValue}
                                setSkillUpdateMultiVaue={setSkillUpdateMultiVaue}
                                renderState={props.renderState}
                            />
                        </div>
                    </div>
                </div>
            </CandidateUpdateModal>
            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deleteCandidateExperience(props.candId, deleteItemId)} />
        </div>
    )

}

export default UpdateEmployment