import React, { useState, useEffect, useRef } from 'react'
import { deleteCandidateCertificateByID, postCandidateCertificateByID, updateCertificateEducationByID } from '../../../../../fetchAPI'
import { Alert, Box, CircularProgress } from '@mui/material'
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal'
import JoditEditor from 'jodit-react';
import AddButton from './component/AddButton'
import customStyles from './CandidateUpdateProfile.module.css'
import CandidateUpdateModal from './component/CandidateUpdateModal'
import SingleCertificateBlock from './component/SingleCertificateBlock'
import EditSVG from './component/EditSVG'
import DeleteSVG from './component/DeleteSVG'
import { useAlert } from '../../../../context/AlertContext'
import RequiredAsterisk from './component/RequiredAsterisk';
import { useLocation } from 'react-router-dom';

function UpdateCertificate(props) {

    const [loader, setLoader] = useState(false)

    const [updateCertificateData, setUpdateCertificateData] = useState({})
    const [certificateData, setCertificateData] = useState({})

    const certificateFileInput = useRef();
    const certificateUpdateFileInput = useRef();

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);

    const alertContext = useAlert();
    const { search } = useLocation();

	useEffect(() => {
	  const scrollToHash = () => {
		const params = new URLSearchParams(search);
		const scrollTo = params.get('scrollTo');
		if (scrollTo) {
		  setTimeout(() => {
			const element = document.getElementById(scrollTo);
			if (element) {
				const offset = element.offsetTop - 220;
				window.scrollTo({ top: offset, behavior: 'smooth' });
			}
		  }, 100);
		}
	  };
  
	  scrollToHash();
	  window.addEventListener('popstate', scrollToHash);

	  return () => {
		window.removeEventListener('popstate', scrollToHash);
	  };
	}, [search]);

    const handleUpdateChange = (name) => (event) => {
        setUpdateCertificateData({
            ...updateCertificateData,
            [name]: event.target.value
        })
    }


    const enableUpdate = (item) => {
        setUpdateCertificateData({
            id: item.id,
            institute: item.institute,
            name: item.name,
            description: item.description || '',
            start: item.start,
            end: item.end,
            duration: item.duration,
            certificate_url: item.certificate_url,
            attachment: null,
            attachmentPath: item.attachment,
        })

    }

    // Jodit configuaration
    const [newCertificateJoditContent, setNewCertificateJoditContent] = useState('')

    useEffect(() => {
        setCertificateData({ ...certificateData, description: newCertificateJoditContent })
    }, [newCertificateJoditContent])

    const handleChange = (name) => (event) => {
        setCertificateData({ ...certificateData, [name]: event.target.value })
    }



    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

    const handleOpen = (itemId) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const [deleteItemId, setDeleteItemId] = useState('')


    const deleteCandidateCertificate = (candId, certiId) => {
        setOpenDeleteModal(false)
        setLoader(true)
        deleteCandidateCertificateByID(candId, certiId).then((response) => {
            if (response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                props.renderState()
                setLoader(false)
            }
            else {
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
            }
        })
    }

    let errorsObj = { institute: '', name: '', start: '', end: '', duration: '' };
    const [updateErrors, setUpdateErrors] = useState({})
    const [errors, setErrors] = useState({})
    const insertCandidateCertificate = ( body) => {

        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.institute) {
            errorObj.institute = 'institute is Required';
            error = true;
        }
        if (!body.name) {
            errorObj.name = 'Certificate title is Required';
            error = true;
        }
        if (!body.start) {
            errorObj.start = 'Start Date is Required';
            error = true;
        }
        if (!body.end) {
            errorObj.end = 'End Date is Required';
            error = true;
        }
        if (!body.duration) {
            errorObj.duration = 'Duration is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            // Do not call the Post API
            setLoader(false);
        }
        else {
            setCertificateData({});
            setAddModalOpen(false);

            

            const formData = new FormData();
            for (let key in body) {

                formData.append(key, body[key]);
            }

            // console.log(formData);
            // return;

            postCandidateCertificateByID(formData).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    // console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false)
                }
            })
        }
    }

    const updateCandidateCertificate = (body) => {

        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.name) {
            errorObj.name = 'Certificate Name is Required';
            error = true;
        }
        if (!body.institute) {
            errorObj.institute = 'Institute is Required';
            error = true;
        }
        if (!body.start) {
            errorObj.start = 'Start Date is Required';
            error = true;
        }
        if (!body.end) {
            errorObj.end = 'End Date is Required';
            error = true;
        }
        if (!body.duration) {
            errorObj.duration = 'Duration is Required';
            error = true;
        }
        setUpdateErrors(errorObj);
        if (error) {
            // Do not call the Post API
            setLoader(false);
        }
        else {
            setUpdateModalOpen(false);
            setUpdateCertificateData({});

            const formData = new FormData();
            for (let key in body) {
                if(key != 'attachmentPath')
                {
                    if(key == 'attachment' && body['attachment'] == null) continue;

                    formData.append(key, body[key]);
                }
                
            }

            updateCertificateEducationByID( body.id, formData).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    // console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }

    return (
        <div id='certificate_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>Training & Certifications</div>
                        <AddButton title="Certificates" onClickHandler={() => setAddModalOpen(true)} />
                    </div>
                </div>
                <div className='card-body'>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            props.certificates?.map((item, index) => {
                                return (
                                    <div key={item.id}>
                                        {index > 0 ? <hr /> : ''}
                                        <div className={customStyles.singleCertificate} >
                                            <div className={customStyles.certificateTitle}>{item.name}</div>

                                            <div className={customStyles.actionContainer} style={{ marginLeft: '10px' }}>
                                                <span style={{ height: '32px' }} onClick={() => { enableUpdate(item); setUpdateModalOpen(true) }}>
                                                    <EditSVG />
                                                </span>
                                                <span style={{ height: '32px' }} onClick={() => handleOpen(item.id)}>
                                                    <DeleteSVG />
                                                </span>
                                            </div>
                                        </div>
                                        <SingleCertificateBlock item={item} />
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
            <CandidateUpdateModal form="candidateCertificateAddForm" open={addModalOpen} onClose={() => [setErrors({}), setAddModalOpen(false), setCertificateData({})]} title='New Certificate'>
                <div>
                    <div className='card-body'>
                        <form id='candidateCertificateAddForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); insertCandidateCertificate(certificateData); }}>
                            <div className={`row text-left`}>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Certificate name <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('name')} placeholder='Enter Certificate Name' type='text' />
                                        {errors.name && <Alert severity="error">{errors.name}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Institute Name <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('institute')} placeholder='Enter Institute Name' type='text' />
                                        {errors.institute && <Alert severity="error">{errors.institute}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Start Date <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('start')} placeholder='Start Date' type='date' />
                                        {errors.start && <Alert severity="error">{errors.start}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>End Date <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('end')} placeholder='End Date' type='date' />
                                        {errors.end && <Alert severity="error">{errors.end}</Alert>}
                                        {errors.dateValid && <Alert severity="error">{errors.dateValid}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-12'>
                                    <div className='form-group'>
                                        <label>Duration <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('duration')} placeholder='Enter Duration' type='text' />
                                        {errors.duration && <Alert severity="error">{errors.duration}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-12'>
                                    <div className='form-group'>
                                        <label>Certificate URL</label>
                                        <input className='form-control atb-input-box' onChange={handleChange('certificate_url')} placeholder='Enter URL' type='text' />
                                        {errors.certificate_url && <Alert severity="error">{errors.certificate_url}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-12 col-lg-12'>
                                    <div className="upload-box" style={{ background: "#FBFBFB" }}>
                                        {certificateData.attachment != null && certificateData.attachment != undefined ? (
                                            <>
                                                <div
                                                    style={{ color: "red", cursor: "pointer" }}
                                                    onClick={() => setCertificateData({ ...certificateData, attachment: null })}
                                                    className="pl-2 pt-2"
                                                >
                                                    <i class="fa fa-times" aria-hidden="true"></i>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div>
                                                        <p className="text-center mb-2">
                                                            {certificateData.attachment?.name}{" "}
                                                        </p>{" "}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: "150px" }}>
                                                <div className="">
                                                    <label
                                                        className="d-flex justify-content-center align-items-center mx-auto w-75 py-2"
                                                        htmlFor="candidate_certificate_file_input"
                                                        style={{
                                                            background: "#EDF7FF",
                                                            borderRadius: "30px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <img
                                                            className=""
                                                            src="/v2/icons/upload-resume.svg"
                                                            alt=""
                                                            style={{ width: "18px" }}
                                                        />
                                                        <p
                                                            className="text-primay mb-0 pl-2  upload-text"
                                                            style={{
                                                                color: "#0079C1",
                                                                fontSize: "18px",
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            Upload Certificate
                                                        </p>
                                                    </label>{" "}
                                                    <p
                                                        className="text-center mb-0 mt-2 upload-text"
                                                        style={{ fontSize: "18px" }}
                                                    >
                                                        Supported Formats: jpg, pdf, upto 2 MB
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        <input ref={certificateFileInput} id="candidate_certificate_file_input" onChange={(e) => setCertificateData({ ...certificateData, attachment: e.target.files[0] })} type="file" />
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-12'>
                                    <div className='form-group'>
                                        <label>Description</label>
                                        <JoditEditor
                                            value={certificateData.description || ''}
                                            buttons={[
                                                'bold', '|',
                                                'italic', '|',
                                                'ul', '|', 'underline', '|']}
                                            className='m-t10'
                                            onChange={(content) => setCertificateData({ ...certificateData, description: content })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>
            <CandidateUpdateModal form="candidateCertificateUpdateForm" open={updateModalOpen} onClose={() => [setUpdateErrors({}), setUpdateModalOpen(false), setUpdateCertificateData({})]} title='Update Certificate'>
                <div>
                    <div className='card-body'>
                        <form id='candidateCertificateUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateCandidateCertificate( updateCertificateData); }}>
                            <div className='row'>

                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Certificate name <RequiredAsterisk/></label>
                                        <input
                                            onChange={handleUpdateChange('name')}
                                            className='form-control atb-input-box m-b10' type='text' value={updateCertificateData.name} />
                                        {updateErrors.name && <Alert severity="error">{updateErrors.name}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Institute Name <RequiredAsterisk/></label>
                                        <input
                                            onChange={handleUpdateChange('institute')}
                                            className='form-control atb-input-box m-b10' type='text' value={updateCertificateData.institute} />
                                        {updateErrors.institute && <Alert severity="error">{updateErrors.institute}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-12 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Start Date <RequiredAsterisk/></label>
                                        <input
                                            onChange={handleUpdateChange('start')}
                                            className='form-control atb-input-box m-b10' type='date' value={updateCertificateData.start} />

                                        {updateErrors.start && <Alert severity="error">{updateErrors.start}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-12 col-lg-6'>
                                    <div className='form-group'>
                                        <label>End Date <RequiredAsterisk/></label>
                                        <input onChange={handleUpdateChange('end')}
                                            className='form-control atb-input-box m-b10' type='date' value={updateCertificateData.end} />
                                        {updateErrors.end && <Alert severity="error">{updateErrors.end}</Alert>}
                                        {updateErrors.dateValid && <Alert severity="error">{updateErrors.dateValid}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-12 col-lg-12'>
                                    <div className='form-group'>
                                        <label>Duration <RequiredAsterisk/></label>
                                        <input
                                            onChange={handleUpdateChange('duration')}
                                            className='form-control atb-input-box m-b10' type='text' value={updateCertificateData.duration} />
                                        {updateErrors.duration && <Alert severity="error">{updateErrors.duration}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-12 col-lg-12'>
                                    <div className='form-group'>
                                        <label>Certificate URL</label>
                                        <input
                                            onChange={handleUpdateChange('certificate_url')}
                                            className='form-control atb-input-box m-b10' type='text' value={updateCertificateData.certificate_url} />
                                        {updateErrors.certificate_url && <Alert severity="error">{updateErrors.certificate_url}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-12 col-lg-12'>
                                    <label style={{marginBottom:'20px'}}>
                                        {
                                            updateCertificateData?.attachmentPath != undefined && updateCertificateData?.attachmentPath != null ?
                                                <div>
                                                    Previous file:
                                                    <a href={`${process.env.REACT_APP_S3_BUCKET}${updateCertificateData.attachmentPath}`} target='_blank'>{updateCertificateData.attachmentPath.split(/[\/|\\]/g)[updateCertificateData.attachmentPath.split(/[\/|\\]/g).length - 1]}</a>
                                                </div>
                                                :
                                                ''
                                        }
                                    </label>
                                    <div className="upload-box" style={{ background: "#FBFBFB" }}>
                                        {updateCertificateData.attachment != null && updateCertificateData.attachment != undefined ? (
                                            <>
                                                <div
                                                    style={{ color: "red", cursor: "pointer" }}
                                                    onClick={() => setUpdateCertificateData({ ...updateCertificateData, attachment: null })}
                                                    className="pl-2 pt-2"
                                                >
                                                    <i class="fa fa-times" aria-hidden="true"></i>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div>
                                                        <p className="text-center mb-2">
                                                            {updateCertificateData.attachment?.name}{" "}
                                                        </p>{" "}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: "150px" }}>
                                                <div className="">
                                                    <label
                                                        className="d-flex justify-content-center align-items-center mx-auto w-75 py-2"
                                                        htmlFor="candidate_update_certificate_file_input"
                                                        style={{
                                                            background: "#EDF7FF",
                                                            borderRadius: "30px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <img
                                                            className=""
                                                            src="/v2/icons/upload-resume.svg"
                                                            alt=""
                                                            style={{ width: "18px" }}
                                                        />
                                                        <p
                                                            className="text-primay mb-0 pl-2  upload-text"
                                                            style={{
                                                                color: "#0079C1",
                                                                fontSize: "18px",
                                                                fontWeight: 700,
                                                            }}
                                                        >
                                                            Upload Certificate
                                                        </p>
                                                    </label>{" "}
                                                    <p
                                                        className="text-center mb-0 mt-2 upload-text"
                                                        style={{ fontSize: "18px" }}
                                                    >
                                                        Supported Formats: jpg, pdf, upto 2 MB
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        <input ref={certificateUpdateFileInput} id="candidate_update_certificate_file_input" onChange={(e) => setUpdateCertificateData({ ...updateCertificateData, attachment: e.target.files[0] })} type="file" />
                                    </div>
                                </div>

                                <div className='col-md-12 col-lg-12'>
                                    <div className='form-group'>
                                        <label>Description</label>
                                        <JoditEditor
                                            buttons={[
                                                'bold', '|',
                                                'italic', '|',
                                                'ul', '|', 'underline', '|']}
                                            className='m-t10'
                                            onChange={(content) => setUpdateCertificateData({ ...updateCertificateData, description: content })}
                                            value={updateCertificateData.description || ''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>
            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deleteCandidateCertificate(props.candId, deleteItemId)} />
        </div>
    )
}

export default UpdateCertificate