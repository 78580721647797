import React, { useEffect, useState } from 'react'
import { updateCandidateBasicInfo } from '../../../../../fetchAPI'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import customStyles from './CandidateUpdateProfile.module.css'
import parse from 'react-html-parser'
import EditSVG from './component/EditSVG'
import CandidateUpdateModal from './component/CandidateUpdateModal'
import { useAlert } from '../../../../context/AlertContext'
import { Box, CircularProgress } from '@mui/material'
import { useLocation } from 'react-router-dom'

function UpdateAbout(props) {
	const { search } = useLocation();

	useEffect(() => {
	  const scrollToHash = () => {
		const params = new URLSearchParams(search);
		const scrollTo = params.get('scrollTo');
		if (scrollTo) {
		  setTimeout(() => {
			const element = document.getElementById(scrollTo);
			if (element) {
				const offset = element.offsetTop - 220;
				window.scrollTo({ top: offset, behavior: 'smooth' });
			}
		  }, 100);
		}
	  };
  
	  scrollToHash();
	  window.addEventListener('popstate', scrollToHash);

	  return () => {
		window.removeEventListener('popstate', scrollToHash);
	  };
	}, [search]);

	const alertContext = useAlert();

	const [updateData, setUpdateData] = useState({})
	const [modalOpen, setModalOpen] = useState(false);

	const updateCandidate = (candId, updateParameters) => {
		setModalOpen(false);
		updateCandidateBasicInfo(candId, updateParameters)
			.then(response => {
				if (response && response.success) {
					alertContext.setResponseMessage(response.response.message)
					alertContext.setOpenAlert(true)
					setUpdateData({});
					props.renderState()
				} else {
					const message = response.errors?.map(msg => {
						alertContext.setResponseMessage(msg.message)
					})
					alertContext.setOpenErrorAlert(true)
				}
			})
	}



	return (
		<div id='about_box'>
			<div className='card shadow-sm'>
				<div className={customStyles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={customStyles.sectionHeader}>Profile Summary</div>
						<button className={`btn ${customStyles.editButton}`} onClick={() => {
							setUpdateData({
								intro: props.basicData.intro,
							}); setModalOpen(true)
						}}>
							<div style={{ marginRight: '10px' }}>Edit</div>
							<EditSVG />
						</button>
					</div>
				</div>
				{
					props.loading ?
						<Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
							<CircularProgress />
						</Box>
						:
						<div className='card-body'>
							{parse(props.basicData.intro)}
						</div>
				}
			</div>
			<CandidateUpdateModal open={modalOpen} onClose={() => setModalOpen(false)} title='Profile Summary' onFormSubmit={() => updateCandidate(props.candId, updateData)}>
				<div>
					<div style={{ padding: '10px' }} className='row text-left m-t30 m-1'>
						<div className='col-lg-12 col-md-12'>
							<div className="form-group">
								<label>Intro<div className='d-inline-block' style={{ color: 'red' }}>*</div></label>
								<ReactQuill
									theme="snow"
									placeholder='Write at least 3 bullet points describing your role. Focus on your achievements and help recruiters understand the context of the company.'
									defaultValue={props.basicData.intro}
									onChange={(content) => setUpdateData({ intro: content })}
								/>
							</div>
						</div>
					</div>
				</div>
			</CandidateUpdateModal>
		</div>
	)
}

export default React.memo(UpdateAbout)