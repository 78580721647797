import React, { useContext, useState, useRef, useEffect } from 'react'
import Select from 'react-select'
import { DropdownContext } from '../../../../context/DropdownContext'
import { insertCandidateSkillByID, updateCandidateSkillByID, deleteCandidateSkillByID } from '../../../../../fetchAPI'
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal'
import { Skeleton } from '@mui/material'
import AddButton from './component/AddButton'
import customStyles from './CandidateUpdateProfile.module.css';
import CandidateUpdateModal from './component/CandidateUpdateModal'
import EditSVG from './component/EditSVG'
import DeleteSVG from './component/DeleteSVG'
import { useAlert } from '../../../../context/AlertContext'
import RequiredAsterisk from './component/RequiredAsterisk'
import { useLocation } from 'react-router-dom'

function UpdateSkill(props) {

    const { skills } = useContext(DropdownContext)

    const [updateSkill, setUpdateSkill] = useState({})
    const [insertSkill, setInsertSkill] = useState({})
    const addYearBox = useRef('')

    const [isLoading, setIsLoading] = useState(false)
    const [addFormValidationMessage, setAddFormValidationMessage] = useState('');

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addFormRef = useRef();

    const alertContext = useAlert();
    const { search } = useLocation();

	useEffect(() => {
	  const scrollToHash = () => {
		const params = new URLSearchParams(search);
		const scrollTo = params.get('scrollTo');
		if (scrollTo) {
		  setTimeout(() => {
			const element = document.getElementById(scrollTo);
			if (element) {
				const offset = element.offsetTop - 220;
				window.scrollTo({ top: offset, behavior: 'smooth' });
			}
		  }, 100);
		}
	  };
  
	  scrollToHash();
	  window.addEventListener('popstate', scrollToHash);

	  return () => {
		window.removeEventListener('popstate', scrollToHash);
	  };
	}, [search]);

    const handleChangeSelect = (name) => (event) => {
        setInsertSkill({ ...insertSkill, [name]: event.value })
        addYearBox.current.focus()
    }

    const enableUpdate = (item) => {
        setUpdateSkill({
            skill_id: item.id,
            skillName: item.skill_name,
            year_of_experience: item.pivot?.year_of_experience,
        })
    }

    // Add Candidate Skill by ID 
    const insertingCandidateSkill = (candId, body) => {
        // Update Candidate Skill By ID API 
        setAddFormValidationMessage('');
        if (!body.skill_id) {
            setAddFormValidationMessage('Please select a skill');
            setIsLoading(false);
            return;
        }
        setAddModalOpen(false);
        insertCandidateSkillByID(candId, body).then((response) => {
            if (response.success) {
                setIsLoading(false)
                alertContext.setOpenAlert(true)
                alertContext.setResponseMessage(response.response.message)
                setInsertSkill({})
                props.renderState()

            }
            else {
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                setInsertSkill({})
                alertContext.setOpenErrorAlert(true)
                setIsLoading(false)
            }
        })
    }

    // Update Candidate Skill by ID 
    const updatingCandidateSkill = (candId, body) => {

        // Update Candidate Skill By ID API 
        setUpdateModalOpen(false);
        updateCandidateSkillByID(candId, body).then((response) => {
            if (response.success) {
                setIsLoading(false)
                alertContext.setOpenAlert(true)
                alertContext.setResponseMessage(response.response.message)
                setUpdateSkill({})
                props.renderState()
            }
            else {
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                setUpdateSkill({})
                alertContext.setOpenErrorAlert(true)
                setIsLoading(false)
            }
        })
    }


    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

    const handleOpen = (itemId, index) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
        setDeleteItemIndex(index);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const [deleteItemId, setDeleteItemId] = useState('')
    const [deleteItemIndex, setDeleteItemIndex] = useState('')


    // Delete Candidate Skill by ID 
    const deletingCandidateSkill = (candId, skillId) => {
        setOpenDeleteModal(false)
        // Update Candidate Skill By ID API 
        deleteCandidateSkillByID(candId, skillId).then((response) => {
            if (response.success) {
                alertContext.setOpenAlert(true)
                alertContext.setResponseMessage(response.response.message)
                props.renderState()
            }
            else {
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })

                alertContext.setOpenErrorAlert(true)
            }
        })
    }

    return (
        <div id='skill_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>Key Skills</div>
                        <AddButton title="Skills" onClickHandler={() => setAddModalOpen(true)} />
                    </div>
                </div>
                <div className='card-body'>
                    {
                        isLoading ?
                            <div className={customStyles.skillSkeletonContainer}>
                                <Skeleton variant="rectangular" width={200} height={30} />
                                <Skeleton variant="rectangular" width={200} height={30} />
                                <Skeleton variant="rectangular" width={200} height={30} />
                                <Skeleton variant="rectangular" width={200} height={30} />
                            </div>
                            :
                            <div className={customStyles.skillContainer}>
                                {props.skills?.map((item, index) => {
                                    return (

                                        <div className={customStyles.singleSkill} key={item.id}>
                                            <div>
                                                <span>{item.skill_name}</span>
                                                <span> - {item.pivot?.year_of_experience} years</span>
                                            </div>
                                            <div className={customStyles.actionContainer} style={{ marginLeft: '10px' }}>
                                                <span style={{ height: '32px' }} onClick={() => { enableUpdate(item); setUpdateModalOpen(true) }}>
                                                    <EditSVG />
                                                </span>
                                                <span style={{ height: '32px' }} onClick={() => handleOpen(item.id, index)}>
                                                    <DeleteSVG />
                                                </span>
                                            </div>
                                        </div>

                                    )
                                })}
                            </div>
                    }
                </div>
            </div>

            <CandidateUpdateModal isContentScrollable={false} form="candidateSkillUpdateForm" open={updateModalOpen} onClose={() => [setUpdateModalOpen(false), setUpdateSkill({})]} title='Key Skill Update'>
                <div className='card-body' style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <form style={{ width: '100%' }} id='candidateSkillUpdateForm' onSubmit={(e) => { e.preventDefault(); updatingCandidateSkill(props.candId, updateSkill); }}>
                        <div className='row' style={{alignItems:'center'}}>
                            <div className='col-md-6 p-10 mb-3'>
                                <label>Skill <RequiredAsterisk/></label>
                                <input type='text' className='form-control'
                                    value={updateSkill.skillName}
                                    disabled
                                    placeholder='Number of Years'
                                    required
                                />
                            </div>
                            <div className='col-md-6 p-10 mb-3'>
                                <label>Number of years <RequiredAsterisk/></label>
                                <input type='number' step='.01' min='0' max='100.00' className='form-control year-box'
                                    value={updateSkill.year_of_experience}
                                    onChange={(e) => setUpdateSkill({ ...updateSkill, year_of_experience: e.target.value })}
                                    placeholder='Number of Years'
                                    required
                                />

                            </div>
                        </div>
                    </form>
                </div>
            </CandidateUpdateModal>
            <CandidateUpdateModal isContentScrollable={false} form="candidateKeySkillAddForm" open={addModalOpen} onClose={() => [setAddModalOpen(false), setAddFormValidationMessage(''), setInsertSkill({})]} title='Key Skills'>
                <div className='card-body' style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <form style={{ width: '100%' }} id='candidateKeySkillAddForm' ref={addFormRef} onSubmit={(e) => { e.preventDefault(); setIsLoading(true); insertingCandidateSkill(props.candId, insertSkill); }}>
                        <div className='row'>
                            <div className='col-md-6 p-10 mb-3' style={{ zIndex: '4' }}>
                                <label>Skill <RequiredAsterisk/></label>
                                <Select
                                    placeholder='Select Skill'
                                    options={skills.map(item => {
                                        return (
                                            { label: item.skill_name, value: item.id }
                                        )
                                    })
                                    }
                                    onChange={handleChangeSelect('skill_id')}
                                    className='skill-update-container'
                                    classNamePrefix='skill-update'
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderRadius: '5px !important',
                                            height: '44px'
                                        }),
                                    }}
                                />
                            </div>
                            <div className='col-md-6 p-10 mb-3'>
                                <label>Number of years <RequiredAsterisk/></label>
                                <input type='number' step='.01' min='0' max='100.00' className='form-control year-box' ref={addYearBox}
                                    onChange={(e) => setInsertSkill({ ...insertSkill, year_of_experience: e.target.value })}
                                    placeholder='Number of Years'
                                    required
                                />

                            </div>
                        </div>
                    </form>
                    {
                        addFormValidationMessage != '' ?
                            <div className={customStyles.validationErrorMessage}>
                                {addFormValidationMessage}
                            </div>
                            : ""
                    }
                </div>
            </CandidateUpdateModal>



            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deletingCandidateSkill(props.candId, deleteItemId)} />
        </div>
    )
}

export default UpdateSkill