import React, { useEffect, useState } from 'react'
import { Alert, Box, CircularProgress } from '@mui/material'
import { postCandidateReference, deleteCandidateReference, updateCandidateReference } from '../../../../../fetchAPI'
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal'
import CandidateUpdateModal from './component/CandidateUpdateModal'
import customStyles from './CandidateUpdateProfile.module.css'
import EditSVG from './component/EditSVG'
import DeleteSVG from './component/DeleteSVG'
import AddButton from './component/AddButton'
import { useAlert } from '../../../../context/AlertContext'
import RequiredAsterisk from './component/RequiredAsterisk'
import { defaultMobileNumber, handleKeyDown, validatePhoneNumber } from '../../../../helpers/helperFunctions'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    input: {
      borderLeft: '1px solid #C4C4C4',
      paddingLeft: "14px",
      marginLeft: "7px",
      padding: "30px 0px",
    },
    outlinedInput: {
      '& .react-tel-input .form-control': {
        borderRadius: '4px',
        borderColor: '#C4C4C4',
        padding: '14px',
        fontSize: '16px',
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '& .react-tel-input .flag-dropdown': {
        border: 'none',
        backgroundColor: 'transparent',
      },
      '& .react-tel-input .form-control:focus': {
        borderColor: '#0275D8',
        boxShadow: '0 0 0 1px #0275D8',
      },
      '& .react-tel-input .selected-flag': {
        marginLeft: '8px',
        marginRight: '12px',
      },
      '& .react-tel-input .country-list': {
        zIndex: 1000,
      },
    }
  });

function UpdateReference(props) {
    const [referenceAddData, setReferenceAddData] = useState({})
    const [updateReferenceData, setUpdateReferenceData] = useState({})

    const [loader, setLoader] = useState(false)

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);

    const alertContext = useAlert();
    const [phoneNumber, setPhoneNumber] = useState("")
    const [country, setCountry] = useState("bd")
    const [isBD, setIsBD] = useState(false);
    const [dialCode, setDialCode] = useState("");
    const classes = useStyles();
    const [isDialCode, setIsDialCode] = useState("")
    const [isActualNumber, setIsActualNumber] = useState("")

    const handlePhoneChange = (value, countryData) => {
        setDialCode(countryData.dialCode); 

        if(country !== countryData.countryCode) {
            setPhoneNumber('');
            setCountry(countryData.countryCode);
        }
        
        if(countryData.countryCode === "bd"){
            setIsBD(true)
        } else {
            setIsBD(false)
        }
        setPhoneNumber(value)
        setUpdateReferenceData({...updateReferenceData, mobile_number: value})
        setReferenceAddData({...referenceAddData, mobile_number: value})
    };

    const enableUpdate = (item) => {
        setPhoneNumber(item.dial_code + item.mobile_number);
        setUpdateReferenceData({
            id: item.id,
            name: item.name,
            designation: item.designation,
            relation: item.relation,
            mobile_number: item.mobile_number,
            email: item.email,
            organization: item.organization,
            dialCode: item.dial_code
        })
        setIsDialCode(item.dial_code)
        setIsActualNumber(item.mobile_number)
    }

    const handleUpdateChange = (name) => (event) => {
        setUpdateReferenceData({
            ...updateReferenceData,
            [name]: event.target.value
        })
    }

    const handleChange = (name) => (event) => {
        setReferenceAddData({ ...referenceAddData, [name]: event.target.value })
    }

    let errorsObj = {};

    const [errors, setErrors] = useState({})

    const insertCandidateReference = (body) => {

        let error = false;
        const errorObj = { ...errorsObj };
        if (!body.name) {
            errorObj.name = 'Name is Required';
            error = true;
        }

        if (!body.designation) {
            errorObj.designation = 'Designation is Required';
            error = true;
        }

        if (!body.email) {
            errorObj.email = 'Email is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            setLoader(false);
        }

        else {
            const actualNumber = body.mobile_number.replace(`${dialCode}`, isBD ? "0" : "")
            const params = {...body, mobile_number: actualNumber, dialCode}


            if (isBD && actualNumber && !validatePhoneNumber(actualNumber)) {
              alertContext.setOpenErrorAlert(true);
              alertContext.setResponseMessage("Please enter a valid phone number!");
              setLoader(false);
              return;
            }
            setAddModalOpen(false);
            setReferenceAddData({});
            postCandidateReference(params).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false)
                }
            })
        }
    }

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

    const handleOpen = (itemId) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const [deleteItemId, setDeleteItemId] = useState('')
    const deleteReference = (id) => {
        setOpenDeleteModal(false)
        setLoader(true)
        deleteCandidateReference(id).then((response) => {
            if (response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                props.renderState()
                setLoader(false)
            }
            else {
                console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
                setLoader(false);
            }
        })
    }

    const [updateErrors, setUpdateErrors] = useState({})
    const updateReference = (body) => {

        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.name) {
            errorObj.name = 'Name is Required';
            error = true;
        }

        if (!body.designation) {
            errorObj.designation = 'Designation is Required';
            error = true;
        }

        if (!body.email) {
            errorObj.email = 'Email is Required';
            error = true;
        }

        setUpdateErrors(errorObj);
        if (error) {
            setLoader(false);
        }
        else {
            let actualNumber;
            if(dialCode) {
                actualNumber = body.mobile_number.replace(`${dialCode}`, isBD ? "0" : " ")
            } else {
                actualNumber = isActualNumber
            }
            const params = {...body, mobile_number: actualNumber, dialCode: dialCode ? dialCode : isDialCode}

            if (isBD && actualNumber && !validatePhoneNumber(actualNumber)) {
              alertContext.setOpenErrorAlert(true);
              alertContext.setResponseMessage("Please enter a valid phone number!");
              setLoader(false);
              return;
            }
            setUpdateModalOpen(false);
            updateCandidateReference(params.id, params).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }

    return (
        <div id='candidate_update_reference_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>References</div>
                        <AddButton title="References" onClickHandler={() => setAddModalOpen(true)} />
                    </div>
                </div>
                <div className='card-body'>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            props.candidateReference?.map((item, index) => {
                                return (
                                    <div key={item.id}>
                                        {index > 0 ? <hr /> : ''}
                                        <div className={customStyles.singleRef} >
                                            <div className={customStyles.refTitle}>
                                                {item.name}
                                            </div>

                                            <div className={customStyles.actionContainer} style={{ marginLeft: '10px' }}>
                                                <span style={{ height: '32px' }} onClick={() => { enableUpdate(item); setUpdateModalOpen(true) }}>
                                                    <EditSVG />
                                                </span>
                                                <span style={{ height: '32px' }} onClick={() => handleOpen(item.id)}>
                                                    <DeleteSVG />
                                                </span>
                                            </div>
                                        </div>
                                        <div className={customStyles.refDesignation}>
                                            {item.designation} | {item.organization}
                                        </div>
                                        <div className={customStyles.refMobileNo}>
                                            {item.dial_code && item.mobile_number ? `${"+" + item.dial_code + item.mobile_number} |` : ""} {item.email}
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
            {/* reference add fields */}
            <CandidateUpdateModal form="candidateReferenceAddForm" open={addModalOpen} onClose={() => [setErrors({}), setAddModalOpen(false), setReferenceAddData({}), setPhoneNumber("")]} title='Add References'>
                <div>
                    <div className='card-body'>
                        <form id='candidateReferenceAddForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); insertCandidateReference(referenceAddData); }}>
                            <div className={`row text-left`}>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Name <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('name')} placeholder='Enter name' type='text' />
                                        {errors.name && <Alert severity="error">{errors.name}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Designation <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('designation')} placeholder='Enter designation' type='text' />
                                        {errors.designation && <Alert severity="error">{errors.designation}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Email Address <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('email')} placeholder='Enter email' type='email' />
                                        {errors.email && <Alert severity="error">{errors.email}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Relation</label>
                                        <input className='form-control atb-input-box' onChange={handleChange('relation')} placeholder='Enter relation' type='text' />
                                        {errors.relation && <Alert severity="error">{errors.relation}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className="form-group">
                                        <label>Mobile Number</label>									
                                        <PhoneInput
                                            country={country}
                                            value={phoneNumber || ''}
                                            onChange={handlePhoneChange}
                                            inputProps={{
                                                name: 'mobile_number',
                                                required: true,
                                                // autoFocus: true,
                                            }}
                                            countryCodeEditable={false}
                                            enableSearch={true}
                                            containerClass={classes.outlinedInput}
                                            inputStyle={{
                                                width: `calc(100% - 20px)`,
                                                border: '1px solid #C4C4C4',
                                                borderRadius: '4px',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                outline: 'none',
                                                paddingTop: '24.3px',
                                                paddingBottom: "24.3px",
                                                marginLeft: "20px",
                                            }}
                                              searchStyle={{width: "90%"}}
                                              dropdownStyle={{width: `${window.innerWidth > 560 ? "600%" : "400%"}`}}
                                            />	
                                        {errors.mobile_number && <Alert severity="error">{errors.mobile_number}</Alert>}
								    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Organization</label>
                                        <input className='form-control atb-input-box' onChange={handleChange('organization')} placeholder='Enter organization' type='text' />
                                        {errors.organization && <Alert severity="error">{errors.organization}</Alert>}
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>

            {/* reference update fields */}
            <CandidateUpdateModal form="candidateReferenceUpdateForm" open={updateModalOpen} onClose={() => [setUpdateErrors({}), setUpdateModalOpen(false), setUpdateReferenceData({}, setPhoneNumber(""))]} title='Update References'>
                <div>
                    <div className='card-body'>
                        <form id='candidateReferenceUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateReference(updateReferenceData); }}>
                            <div className='row'>
                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Name <RequiredAsterisk/></label>
                                        <input value={updateReferenceData.name} className='form-control atb-input-box' onChange={handleUpdateChange('name')} placeholder='Enter name' type='text' />
                                        {updateErrors.name && <Alert severity="error">{updateErrors.name}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Designation <RequiredAsterisk/></label>
                                        <input value={updateReferenceData.designation} className='form-control atb-input-box' onChange={handleUpdateChange('designation')} placeholder='Enter designation' type='text' />
                                        {updateErrors.designation && <Alert severity="error">{updateErrors.designation}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Email Address <RequiredAsterisk/></label>
                                        <input value={updateReferenceData.email} className='form-control atb-input-box' onChange={handleUpdateChange('email')} placeholder='Enter email' type='email' />
                                        {updateErrors.email && <Alert severity="error">{updateErrors.email}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Relation</label>
                                        <input value={updateReferenceData.relation} className='form-control atb-input-box' onChange={handleUpdateChange('relation')} placeholder='Enter relation' type='text' />
                                        {updateErrors.relation && <Alert severity="error">{updateErrors.relation}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className="form-group">
                                        <label>Mobile Number</label>
                                        <PhoneInput
                                            country={country}
                                            value={phoneNumber || ''}
                                            onChange={handlePhoneChange}
                                            inputProps={{
                                                name: 'mobile_number',
                                                required: true,
                                                // autoFocus: true,
                                            }}
                                            countryCodeEditable={false}
                                            enableSearch={true}
                                            containerClass={classes.outlinedInput}
                                            inputStyle={{
                                                width: `calc(100% - 20px)`,
                                                border: '1px solid #C4C4C4',
                                                borderRadius: '4px',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                outline: 'none',
                                                paddingTop: '24.3px',
                                                paddingBottom: "24.3px",
                                                marginLeft: "20px",
                                            }}
                                              searchStyle={{width: "90%"}}
                                              dropdownStyle={{width: `${window.innerWidth > 560 ? "600%" : "400%"}`}}
                                            />									
                                        {updateErrors.mobile_number && <Alert severity="error">{updateErrors.mobile_number}</Alert>}
								    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Organization</label>
                                        <input value={updateReferenceData.organization} className='form-control atb-input-box' onChange={handleUpdateChange('organization')} placeholder='Enter organization' type='text' />
                                        {updateErrors.organization && <Alert severity="error">{updateErrors.organization}</Alert>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>
            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deleteReference(deleteItemId)} />
        </div>
    )
}

export default UpdateReference