import React, { useEffect, useState, useContext } from 'react'
import HeroSection from '../components/HeroSection/HeroSection'
import { getAllCategories } from './../../fetchAPI'
import { JobSearchContext } from '../context/JobSearchContext'
import { Link } from 'react-router-dom'
import GlobalLoader from '../components/GlobalLoader'
import NoResultsFound from '../components/NoResultsFound'
import { Box, Breadcrumbs, Card, CardHeader, Divider, Grid, Stack, Typography } from '@mui/material'

function ExploreCategories() {

    const [categoriesList, setCategoriesList] = useState('')
    const [categoryLoading, setCategoryLoading] = useState(false)

    const { setSearchDepartment, setDefaultSelectDepartment } = useContext(JobSearchContext)


    useEffect(() => {
        gettingCategoriesList()
    }, [])

    const gettingCategoriesList = () => {
        setCategoryLoading(true)
        getAllCategories().then(response => {
            if (response && response.success) {
                setCategoriesList(response?.response?.data)
                setCategoryLoading(false)
            }
            else {
                console.log('error: ', response)
            }
        })
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    width: '100%',
                    height: '350px',
                    backgroundColor: '#0079C1'
                }}
            />

            <Box
                sx={{
                    position: 'relative',
                    top: { xs: '-330px', md: '-300px' },
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '-250px'
                }}
            >
                <Stack spacing={4} sx={{ width: { xs: '100%', md: '80%' } }}>

                    {/* Breadcrumb */}
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        sx={{
                            display: { xs: 'none', md: 'block' },
                            marginBottom: '10px',
                        }}
                    >
                        <Link
                            underline="hover"
                            to={`/`}
                            style={{
                                color: 'white',
                                '&:hover': { color: 'lightgray' },
                            }}
                        >
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            to="#"
                            aria-current="page"
                            style={{
                                color: '#dbdbdb',
                                '&:hover': { color: '#dbdbdb' },
                            }}
                        >
                            All Category
                        </Link>
                    </Breadcrumbs>

                    {/* Category Card */}
                    <Card
                        sx={{
                            display: 'flex',
                            boxShadow: '0px 4px 100px 0px rgba(0, 0, 0, 0.25)',
                            borderRadius: '6px',
                            justifyContent: 'flex-start',
                            margin: '10px',
                            flexDirection: 'column'
                        }}
                    >

                        {/* Title */}
                        <Box
                            sx={{
                                borderBottom: '1px solid #A9A9A9',
                                width: '100%',
                                padding: 2
                            }}
                        >
                            <CardHeader
                                title={'Explore Jobs By Category'}
                                sx={{
                                    '.MuiCardHeader-title': {
                                        paddingBottom: '10px', // Default padding
                                        fontSize: '23px', // Default font size
                                        '@media (max-width: 600px)': {
                                            paddingBottom: '0px', // Adjust padding for smaller screens
                                            fontSize: '18px' // Adjust font size for smaller screens
                                        },
                                    },
                                }}
                            />
                        </Box>

                        <Box m={{ xs: 2, md: 5 }}>
                            {
                                categoryLoading ?
                                    <GlobalLoader height='50vh' />
                                    :
                                    <Grid container spacing={2}>
                                        {categoriesList.length > 0 ?
                                            categoriesList.map((category, index) => (
                                                <Grid key={index} item md={3} sm={6} xs={12}>
                                                    <Link
                                                        to={{
                                                            pathname: '/search/jobs',
                                                            state: { fromHome: true }
                                                        }}
                                                        onClick={() => {
                                                            setSearchDepartment(category.id);
                                                            setDefaultSelectDepartment({ label: category.name, value: category.id });
                                                        }}
                                                    >
                                                        <Card
                                                            sx={{
                                                                padding: { xs: 2, md: 3 },
                                                                maxHeight: '100px'
                                                            }}
                                                        >
                                                            <Stack spacing={5} direction={'row'} alignItems={'center'}>
                                                                <img
                                                                    src={category?.icon}
                                                                    width={'auto'}
                                                                    height={'auto'}
                                                                    alt="Category image"
                                                                />
                                                                <Stack spacing={1} sx={{ marginLeft: '25px !important', }}>
                                                                    <Typography
                                                                        variant='h3'
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            fontWeight: 500,
                                                                        }}
                                                                    >
                                                                        {category?.name}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant='h3'
                                                                        sx={{
                                                                            fontSize: '16px',
                                                                            fontWeight: 600,
                                                                            color: '#0079C1'
                                                                        }}
                                                                    >
                                                                        {`[${category?.job_count}]`}
                                                                    </Typography>
                                                                </Stack>
                                                            </Stack>
                                                        </Card>
                                                    </Link>
                                                </Grid>
                                            )) : <NoResultsFound />
                                        }
                                    </Grid >
                            }
                        </Box>
                    </Card>
                </Stack>
            </Box>
        </React.Fragment >


    )
}

export default ExploreCategories