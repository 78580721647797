import React, { useContext, useEffect, useState } from 'react'
import customStyles from './CandidateUpdateProfile.module.css'
import { Link } from "react-router-dom";
import { deleteResume, downloadResume, getResumeListById } from '../../../../../fetchAPI';
import moment from 'moment';
import { Box, CircularProgress } from '@mui/material';
import { CandidateContext } from '../../../../context/CandidateContext';
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal';
import { useAlert } from '../../../../context/AlertContext';
import { useLocation } from 'react-router-dom';

function UpdateResume(props) {
	const { candidateData } = useContext(CandidateContext);
	const alertContext = useAlert();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [selectedResume, setSelectedResume] = useState();
	const [recallUseEffect, setRecallUseEffect] = useState(1);
	const [resumes, setResumes] = useState([]);
	const [resumeLoading, setResumeloading] = useState(false)
	const { search } = useLocation();

	useEffect(() => {
	  const scrollToHash = () => {
		const params = new URLSearchParams(search);
		const scrollTo = params.get('scrollTo');
		if (scrollTo) {
		  setTimeout(() => {
			const element = document.getElementById(scrollTo);
			if (element) {
				const offset = element.offsetTop - 220;
				window.scrollTo({ top: offset, behavior: 'smooth' });
			}
		  }, 100);
		}
	  };
  
	  scrollToHash();
	  window.addEventListener('popstate', scrollToHash);

	  return () => {
		window.removeEventListener('popstate', scrollToHash);
	  };
	}, [search]);

	useEffect(() => {
		setResumeloading(true)
		getResumeListById(candidateData.id)
		  .then((response) => {
			setResumes(response.response.data);
			setResumeloading(false)
		  })
		  .catch((error) => {
			console.error("Error:", error);
		  });
	  }, [candidateData, recallUseEffect]);

	const resumeDownload = (resumeId, resumeName) => {
		downloadResume(resumeId).then((response) => response.blob())
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(
					new Blob([blob]),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					`${resumeName}`,
				);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
			});
	}

	function getFileNameFromMediaLink(link) {
		let a = link.split('/');
		return a[a.length - 1];
	}

	const handleDeleteClick = (candidateId, resumeId) => {
		deleteResume(candidateId, resumeId).then((response) => {
		  if (response) {
			alertContext.setResponseMessage(response.response.message);
			alertContext.setOpenAlert(true);
			setRecallUseEffect((prev) => prev + 1);
			setOpenDeleteModal(false);
		  } else {
			alertContext.setResponseMessage("Something went wrong!");
			alertContext.setOpenErrorAlert(true);
		  }
		});
	  };

	const handleOpen = (itemId, index) => {
		setOpenDeleteModal(true);
		setSelectedResume(index);
	  };

	  const handleClose = () => {
		setOpenDeleteModal(false);
	  };

	return (
		<div id='resume_box'>
			<div className='card shadow-sm'>
				<div className={customStyles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={customStyles.sectionHeader}>Resume</div>
						<Link to="/cv-manager">
							<button className={`btn ${customStyles.editButton}`} >
								<div style={{ marginRight: '10px' }}>Manage Resume</div>
							</button>
						</Link>
					</div>
				</div>
				{
					resumeLoading ?
						<Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
							<CircularProgress />
						</Box>
						:
						<div className='card-body'>
							<div className={customStyles.resumeHint}>
								Resume is the most important document recruiters look for. Recruiters generally do not look at profiles without resumes.
							</div>
							{
								resumes?.map((item, index) => {
									return (
										<div key={item.id}>
											<hr />
											<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px' }}>
												<div>
													<div className={customStyles.resumeTitle}>
														{getFileNameFromMediaLink(item.resume_link)}
													</div>
													<div className={customStyles.resumeUploadedOn}>Uploaded on {moment(item.created_at).format("MMM Do YYYY")}</div>
												</div>
												<div style={{ display: "flex", alignItems: "center" }}>
													<div style={{ cursor: 'pointer' }} onClick={() => resumeDownload(item.id, getFileNameFromMediaLink(item.resume_link))}>
														<svg width="32" height="32" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
															<circle cx="21" cy="21" r="21" fill="#E5F5FF" />
															<path d="M20.1394 23.3634V11.9175C20.1394 10.5462 20.4277 10.0241 21.2068 10.0008C21.986 9.97738 22.3133 10.4916 22.3133 11.8707V23.3011C22.695 23.4647 22.8041 23.1686 22.96 23.0128C24.1131 21.8752 25.2429 20.7298 26.3961 19.5922C27.0895 18.8988 27.7986 18.7663 28.2349 19.2962C28.7803 19.9662 28.5232 20.5272 27.9622 21.0805C26.0221 23.005 24.1131 24.9763 22.1808 26.893C21.4795 27.5942 20.9809 27.5942 20.2719 26.893C18.3291 24.9607 16.3968 23.0258 14.4749 21.0883C13.8204 20.4338 13.7425 19.7637 14.2645 19.3195C14.7865 18.8754 15.4488 19.0079 15.9709 19.5533C17.2565 20.8857 18.5655 22.2025 19.8667 23.527L20.1394 23.3634Z" fill="#0079C1" />
															<path d="M21.1019 31.1699H14.0271C12.796 31.1699 12.4688 30.8505 12.4688 29.6116C12.4688 28.8324 12.4688 28.0533 12.4688 27.2741C12.4688 26.4949 12.7804 26.0274 13.5752 26.0508C14.3699 26.0742 14.6114 26.5884 14.6426 27.3053C14.7049 29.0428 14.7127 29.0428 16.4347 29.0428C19.8864 29.0428 23.3459 29.0428 26.7976 29.0428C27.5767 29.0428 27.8027 28.8168 27.7247 28.0922C27.6741 27.6912 27.6741 27.2855 27.7247 26.8845C27.7483 26.6469 27.8612 26.4271 28.0405 26.2695C28.2198 26.1119 28.4523 26.0282 28.6909 26.0352C28.8132 26.0107 28.9393 26.0117 29.0611 26.0383C29.183 26.0648 29.298 26.1164 29.399 26.1895C29.5 26.2627 29.5848 26.356 29.648 26.4636C29.7112 26.5711 29.7514 26.6906 29.7662 26.8144C29.9142 27.9889 29.9142 29.1773 29.7662 30.3518C29.6727 31.0375 29.0649 31.131 28.465 31.131L21.1019 31.1699Z" fill="#0079C1" />
														</svg>
													</div>
													<img
													className='mb-2 ml-2'
                                                      style={{ width: "33px", cursor: "pointer" }}
                                                      src="/v2/icons/trash.svg"
                                                      alt=""
                                                      onClick={() =>
                                                        handleOpen(candidateData.id, item.id)
                                                      }
                                                    />
												</div>
											</div>
										</div>

									)
								})
							}
						</div>
				}
			</div>
			<UpdateDeleteConfirmationModal
              open={openDeleteModal}
              onClose={handleClose}
              deleteItem={() => handleDeleteClick(candidateData.id, selectedResume)}
            />
		</div>
	)
}

export default React.memo(UpdateResume)