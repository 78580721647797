import React, { useState, useContext } from 'react'
import './JobDetailsEmployeSidebar.css'
import { CandidateContext } from '../../../context/CandidateContext'
import { UserContext } from '../../../context/UserContext'
import { followCompany, unFollowCompany } from '../../../../fetchAPI'
import SuccessAlert from '../../Alerts/SuccessAlert'
import { Link } from 'react-router-dom'
import { Typography, Skeleton, Stack, Box, Divider, Tooltip } from '@mui/material'
import EmployerProfilePicture from '../../EmployerComponents/EmployerProfilePicture'
import ShowMoreOrShowLessComponent from './ShowMoreOrShowLessComponent.js'
import { LoadingButton } from '@mui/lab'
import { JobSearchContext } from '../../../context/JobSearchContext.js'
import { useHistory } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function WebsiteIcon(){
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="20" fill="#E5F5FF"/>
        <g clip-path="url(#clip0_6585_32583)">
        <path d="M19.4499 28.9917C17.3468 28.9917 15.2437 28.9917 13.1406 28.9917C11.2058 28.9917 10.0401 27.8584 10.024 25.9063C9.99199 21.6339 9.99199 17.3615 10.024 13.0891C10.024 11.125 11.1938 10.0239 13.1366 10.0078C14.9954 10.0078 16.8541 10.0078 18.7128 10.0078C19.3818 10.0078 20.0948 10.0723 20.0788 10.9354C20.0628 11.7985 19.3417 11.8308 18.6767 11.8308C16.9942 11.8308 15.3118 11.8308 13.6293 11.8308C12.1672 11.8308 11.9469 12.0405 11.9469 13.444C11.9469 17.4771 11.9469 21.5022 11.9469 25.5192C11.9469 26.8824 12.2113 27.1324 13.5493 27.1324C17.5552 27.1324 21.553 27.1324 25.5429 27.1324C26.7967 27.1324 27.0612 26.8501 27.0692 25.5474C27.0692 23.9341 27.0692 22.2967 27.0692 20.6754C27.0598 20.3927 27.0704 20.1096 27.1012 19.8284C27.1813 19.288 27.4417 18.8726 28.0386 18.8726C28.164 18.8677 28.289 18.8895 28.4054 18.9367C28.5218 18.9839 28.6271 19.0554 28.7141 19.1464C28.8011 19.2375 28.868 19.346 28.9104 19.465C28.9527 19.5839 28.9697 19.7105 28.9599 19.8365C28.9599 22.0588 29.048 24.2729 28.9078 26.5033C28.8157 28.0076 27.6219 28.9231 26.0836 28.9554C23.8724 29.0159 21.6612 28.9917 19.4499 28.9917Z" fill="#0079C1"/>
        <path d="M26.7465 13.4195C25.8291 14.3794 24.9197 15.3433 23.9944 16.2911C22.9128 17.3962 21.8232 18.4945 20.7256 19.5861C20.2088 20.0903 19.648 20.8566 18.8908 20.0741C18.1337 19.2917 18.8908 18.7997 19.3876 18.3076C21.2143 16.4524 23.049 14.6093 24.8796 12.7581C25.116 12.5201 25.3324 12.262 25.733 11.8184C24.8797 11.8184 24.2628 11.8184 23.6459 11.8184C23.029 11.8184 22.5803 11.5804 22.5722 10.919C22.5642 10.2576 23.029 10.0115 23.6218 10.0075C24.9879 10.0075 26.3539 10.0075 27.7199 10.0075C27.888 9.988 28.0583 10.0067 28.2184 10.0621C28.3784 10.1175 28.5241 10.2083 28.6448 10.3278C28.7654 10.4474 28.8579 10.5926 28.9155 10.7528C28.9731 10.913 28.9943 11.0841 28.9777 11.2537C29.0057 12.6613 29.0017 14.0769 28.9777 15.4845C28.9777 16.0894 28.7534 16.6581 28.0404 16.6621C27.3273 16.6662 27.119 16.1015 27.0909 15.4966C27.0629 14.8916 27.0909 14.2382 27.0909 13.6091L26.7465 13.4195Z" fill="#0079C1"/>
        </g>
        <defs>
        <clipPath id="clip0_6585_32583">
        <rect width="19" height="19" fill="white" transform="translate(10 10)"/>
        </clipPath>
        </defs>
        </svg>
    )
}

function AddressIcon(){
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="40" height="40" rx="20" fill="#E5F5FF"/>
        <path d="M17.6348 28.7733V25.7156C17.6348 24.9351 18.2722 24.3023 19.0584 24.3023H21.9326C22.3102 24.3023 22.6723 24.4512 22.9393 24.7163C23.2063 24.9813 23.3563 25.3408 23.3563 25.7156V28.7733C23.3539 29.0978 23.4821 29.4099 23.7124 29.6402C23.9427 29.8705 24.256 30 24.5829 30H26.5438C27.4596 30.0024 28.3388 29.6428 28.9872 29.0008C29.6356 28.3588 30 27.487 30 26.5778V17.8669C30 17.1325 29.6721 16.4358 29.1046 15.9647L22.434 10.6759C21.2737 9.74856 19.6111 9.7785 18.4854 10.747L11.967 15.9647C11.3727 16.422 11.0176 17.1206 11 17.8669V26.5689C11 28.4639 12.5474 30 14.4562 30H16.3723C17.0512 30 17.603 29.4562 17.6079 28.7822L17.6348 28.7733Z" stroke="#0079C1" stroke-width="1.5"/>
        </svg>
    )
}

function ArrowIcon() {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.003 10.997L22.996 10.996M22.996 10.996L16.2024 4.20241M22.996 10.996L16.2024 17.7897" stroke="#0079C1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

function CXOJobDetailsEmployeSidebar(props) {
    const { followEmployerIds, settingCandidateData, candidateData } = useContext(CandidateContext)
    const { setEmployeName } = useContext(JobSearchContext)
    const { userData } = useContext(UserContext)

    const [openAlert, setOpenAlert] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [isExpanded, setIsExpanded] = useState(false);

    const history = useHistory()

    const unSaveEmployer = (candId, empId) => {
        setSaveLoading(false);
        setLoading(true);

        unFollowCompany(candId, empId)
            .then(response => {
                if (response && response.success) {
                    settingCandidateData()
                    setResponseMessage(response.response.message)
                    setOpenAlert(true)
                } else {
                    // Handle failure, show error message or take appropriate action
                    console.log(response)
                }
            })
            .catch(error => {
                // Handle errors, show error message or take appropriate action
                console.log(error)
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const saveEmployer = (candId, empId) => {

        if (userData) {
            setLoading(false);
            setSaveLoading(true);

            followCompany(candId, empId)
                .then(response => {
                    if (response && response.success) {
                        settingCandidateData()
                        setResponseMessage(response.response.message)
                        setOpenAlert(true)
                    } else {
                        // Handle failure, show error message or take appropriate action
                        console.log(response)
                    }
                })
                .catch(error => {
                    // Handle errors, show error message or take appropriate action
                    console.log(error)
                })
                .finally(() => {
                    // setLoading(false);
                });
        }
        else {
            history.push('/login')
        }

    }

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };


    // Parsing HTML from Rich Editor data
    const introHtml = props.jobData?.employer?.intro;
    const parser = new DOMParser();
    const doc = parser.parseFromString(introHtml, 'text/html');
    const introPlainText = doc.body.textContent || "";

    // prepare company website link in proper format
    const getCompanyWebLink = (url) => {
        // If the URL doesn't start with http:// or https://, prepend https://
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            if (!url.startsWith('www.')) {
            // If the URL doesn't start with www., prepend it
            return `https://www.${url}`;
            } else {
            return `https://${url}`;
            }
        }
        return url;
    };

    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Stack
                spacing={props.jobDataLoading ? 2 : 3}
                sx={{
                    padding: 2,
                }}
            >
                {
                    props.jobDataLoading ?

                        // Skeleton loader when data is still being fetched
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Skeleton variant="circular" width={80} height={80} />
                            <Skeleton variant="text" width={200} sx={{ marginTop: 2 }} />
                            <Skeleton variant="text" width={200} />
                            <Skeleton variant="text" width={200} />
                            <Skeleton variant="text" height={50} width={200} />
                            <Skeleton variant="rounded" height={120} width={200} />
                        </Box>
                        :
                        // Job Details Employer Sidebar after data is fetched

                        candidateData?.is_cxo_eligible || userData?.id === props.jobData?.employer?.user_id ?
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Tooltip title='Go to Profile'>
                                        <Link
                                            to={`/employer/profile/${props.jobData?.employer?.slug}`}
                                        >
                                            {
                                                candidateData?.is_cxo_eligible || userData?.id === props.jobData?.employer?.user_id ?
                                                    <EmployerProfilePicture
                                                        profile_picture={props.jobData.employer?.profile_pic_base64}
                                                        height={200}
                                                        width={200}
                                                    />
                                                    :
                                                    <img src='/images/cxo/blurred-image.png' />
                                            }


                                        </Link>
                                    </Tooltip>
                                </Box>
                                <Link
                                    to={`/employer/profile/${props.jobData?.employer?.slug}`}
                                >
                                    <Typography
                                        variant='h5'
                                        align='center'
                                        sx={{
                                            marginTop: 4,
                                            color: '#0275D8',
                                            fontWeight: 700,
                                            '&:hover': {
                                                textDecoration: 'underline'
                                            },
                                        }}
                                    >
                                        {
                                            candidateData?.is_cxo_eligible || userData?.id === props.jobData?.employer?.user_id ?
                                                props.jobData.employer?.employer_name
                                                :
                                                <img src='/images/cxo/blurred-text.png' />
                                        }
                                    </Typography>
                                </Link>
                                {
                                    userData.user_role_id !== 3 ?
                                        followEmployerIds.includes(props.jobData.employer?.id) ?
                                            <React.Fragment>
                                                <Box mt={6} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                    <LoadingButton
                                                        loading={loading}
                                                        variant='contained'
                                                        onClick={() => unSaveEmployer(candidateData.id, props.jobData.employer?.id)}
                                                        sx={{
                                                            backgroundColor: '#E5F5FF',
                                                            borderRadius: '60px',
                                                            color: 'white',
                                                            padding: "10px 30px",
                                                            boxShadow: 0,
                                                            '&:hover': {
                                                                backgroundColor: '#E5F5FF',
                                                            },
                                                        }}
                                                    >
                                                        <span style={{color: "#0079C1"}}>Unfollow</span>
                                                    </LoadingButton>
                                                </Box>
                                            </React.Fragment>
                                            :
                                            <Box mt={6} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                <LoadingButton
                                                    loading={saveLoading}
                                                    variant='contained'
                                                    onClick={() => saveEmployer(candidateData.id, props.jobData.employer.id)}
                                                    sx={{
                                                        backgroundColor: '#E5F5FF',
                                                        borderRadius: '60px',
                                                        color: 'white',
                                                        padding: "10px 30px",
                                                        boxShadow: 0,
                                                        '&:hover': {
                                                            backgroundColor: '#E5F5FF',
                                                        },
                                                    }}
                                                >
                                                    <AddCircleOutlineIcon style={{color: "#0079C1", marginRight: "8px"}} /> <span style={{color: "#0079C1"}}>Follow</span>
                                                </LoadingButton>
                                            </Box>

                                        :
                                        ""
                                }

                                <Divider />
                                
                                <Box 
                                    sx={{
                                    overflow: 'hidden',
                                    maxHeight: isExpanded ? 'none' : '155px',
                                    transition: 'max-height 0.3s ease',
                                }}
                                >
                                    <Typography
                                        variant='h6'
                                        align='left'
                                        sx={{
                                            marginTop: 1,
                                            color: '#0275D8',
                                            fontWeight: 700,
                                            fontSize: '20px'
                                        }}

                                    >
                                        Company overview
                                    </Typography>
                                    {
                                        props.jobData?.employer?.intro ?
                                            introPlainText
                                            :
                                            ""
                                    }
                                </Box>
                                {props.jobData?.employer?.intro?.length > 5*30 ? 
                                    <Typography
                                        variant='body1'
                                        sx={{
                                            alignSelf: 'flex-start',
                                            position: 'relative',
                                            zIndex: 1,
                                            color: '#ED7200',
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                        }}
                                        onClick={toggleExpansion}
                                        >
                                        {isExpanded ? 'Show less' : 'Show more'}
                                    </Typography>
                                    :
                                    null
                                }

                                <Divider />

                                <Stack
                                    direction={'row'}
                                    spacing={1}
                                // alignItems={'center'}
                                >
                                    <Stack><WebsiteIcon /></Stack>
                                    <Stack spacing={1}>
                                        <Typography sx={{color: "#424447", fontSize: "16px", fontWeight: 600}}>Website: </Typography>
                                        <Typography
                                            variant='h6'
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                color: "#0079C1",
                                                '&:hover': {
                                                    textDecoration: 'underline'
                                                },
                                                wordBreak: "break-all"
                                            }}
                                        >
                                            {props.jobData?.employer?.web ? 
                                            <a href={getCompanyWebLink(props.jobData?.employer?.web)} target="_blank" rel="noopener noreferrer">
                                                {props.jobData?.employer?.web}
                                            </a>
                                            :
                                            ""
                                            }
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Stack
                                    direction={'row'}
                                    spacing={1}
                                // alignItems={'center'}
                                >
                                    <Stack><AddressIcon /></Stack>
                                    <Stack spacing={1}>
                                        <Typography sx={{color: "#424447", fontSize: "16px", fontWeight: 600}}>Address: </Typography>
                                        <Typography
                                            variant='h6'
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                color: "#424447",
                                                wordBreak: "break-all"
                                            }}
                                        >
                                            {props.jobData?.employer?.map_location ? 
                                            props.jobData?.employer?.map_location
                                            :
                                            ""
                                            }
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Divider />

                                <Box onClick={() => setEmployeName(props.jobData?.employer?.employer_name)}>
                                    <Typography
                                        variant='h6'
                                        align='left'
                                        sx={{
                                            // marginTop: 2,
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: '#0079C1',
                                        }}
                                    >
                                        <Link 
                                        to={{
                                            pathname: '/search/jobs',
                                            state: { fromHome: true }
                                        }}
                                        >
                                            <Box sx={{
                                                marginLeft: "4px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                color: "#0079C1",
                                                gap: 1,
                                            }}>
                                                More Jobs From This Company
                                                <span style={{width: "20px"}}>
                                                <ArrowIcon />
                                                </span>   
                                            </Box>
                                        </Link>
                                    </Typography>
                                </Box>

                            </>
                            :
                            <img src='/images/cxo/blurred-sidebar.png' width={'auto'} />

                }
            </Stack>
            <SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />
        </Box >
    )
}

export default CXOJobDetailsEmployeSidebar