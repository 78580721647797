import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-scroll';
import { Box } from '@mui/material';
import CandidateDashboardAvatar from '../../CandidateDashboardSideBar/CandidateDashboardAvatar';
import CandidateDashboardDesignation from '../../CandidateDashboardSideBar/CandidateDashboardDesignation';
import { useLocation } from 'react-router-dom';

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: '4px',

    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: '80%',
        width: '100%',
        borderRadius: '5px 5px 0px 0px',
        backgroundColor: '#635ee7',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: 600,
        fontFamily: 'Epilogue',
        fontSize: theme.typography.pxToRem(16),
        marginRight: theme.spacing(1),
        color: '#1D1E1B',
        '&.Mui-selected': {
            color: '#0275D8',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '&.MuiButtonBase-root': {
            outline: 'none'
        }
    }),
);

export default function CandidateUpdateNav() {

    const [value, setValue] = React.useState(0);
    const { search } = useLocation();

	useEffect(() => {
	  const scrollToHash = () => {
		const params = new URLSearchParams(search);
		const scrollTo = params.get('scrollTo');
		if (scrollTo) {
		  setTimeout(() => {
			switch(scrollTo) {
                case 'basic_info_block': setValue(0); break;
                case 'about_box': setValue(1); break;
                case 'skill_box': setValue(2); break;
                case 'employment_box': setValue(3); break;
                case 'education_box': setValue(4); break;
                case 'resume_box': setValue(5); break;
                case 'project_box': setValue(6); break;
                case 'certificate_box': setValue(7); break;
                case 'personal_box': setValue(8); break;
                case 'update_candidate_career_box': setValue(9); break;
                case 'candidate_update_language_box': setValue(10); break;
                case 'candidate_update_hobby_box': setValue(11); break;
                case 'candidate_update_reference_box': setValue(12); break;
                case 'social_box': setValue(13); break;
                default: setValue(0);
            }
		  }, 100);
		}
	  };
  
	  scrollToHash();
	  window.addEventListener('popstate', scrollToHash);

	  return () => {
		window.removeEventListener('popstate', scrollToHash);
	  };
	}, [search]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box className='card shadow-sm' sx={{ position: 'sticky', top: { xs: '95px', sm: '95px', md: '147px' }, zIndex: '99', width: '100%' }}>
            <Box sx={{ padding: '20px 0px 20px 20px', display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
                <CandidateDashboardAvatar />
                <Box sx={{ marginLeft: '15px' }}>
                    <CandidateDashboardDesignation />
                </Box>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <StyledTabs
                    allowScrollButtonsMobile
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ fontFamily: 'Epilogue' }}
                >
                    <StyledTab label={<Link onClick={() => setValue(0)} smooth={true} offset={-200} duration={500} to='basic_info_block'>Basic Info</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(1)} smooth={true} offset={-200} duration={500} to='about_box'>Profile Summary</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(2)} smooth={true} offset={-200} duration={500} to='skill_box'>Key Skills</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(3)} smooth={true} offset={-200} duration={500} to='employment_box'>Employment</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(4)} smooth={true} offset={-200} duration={500} to='education_box'>Education</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(5)} smooth={true} offset={-200} duration={500} to='resume_box'>Resume</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(6)} smooth={true} offset={-200} duration={500} to='project_box'>Project</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(7)} smooth={true} offset={-200} duration={500} to='certificate_box'>Certificate</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(8)} smooth={true} offset={-200} duration={500} to='personal_box'>Personal Info</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(9)} smooth={true} offset={-200} duration={500} to='update_candidate_career_box'>Career Info</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(10)} smooth={true} offset={-200} duration={500} to='candidate_update_language_box'>Language</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(11)} smooth={true} offset={-200} duration={500} to='candidate_update_hobby_box'>Hobby</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(12)} smooth={true} offset={-200} duration={500} to='candidate_update_reference_box'>Reference</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(13)} smooth={true} offset={-200} duration={500} to='social_box'>Social Profile</Link>} />
                </StyledTabs>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                <StyledTabs
                    allowScrollButtonsMobile
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{ fontFamily: 'Epilogue' }}
                >
                    <StyledTab label={<Link onClick={() => setValue(0)} smooth={true} offset={-320} duration={500} to='basic_info_block'>Basic Info</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(1)} smooth={true} offset={-320} duration={500} to='about_box'>Profile Summary</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(2)} smooth={true} offset={-320} duration={500} to='skill_box'>Key Skills</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(3)} smooth={true} offset={-320} duration={500} to='employment_box'>Employment</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(4)} smooth={true} offset={-320} duration={500} to='education_box'>Education</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(5)} smooth={true} offset={-320} duration={500} to='resume_box'>Resume</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(6)} smooth={true} offset={-320} duration={500} to='project_box'>Project</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(7)} smooth={true} offset={-320} duration={500} to='certificate_box'>Certificate</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(8)} smooth={true} offset={-320} duration={500} to='personal_box'>Personal Info</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(9)} smooth={true} offset={-320} duration={500} to='update_candidate_career_box'>Career Info</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(10)} smooth={true} offset={-320} duration={500} to='candidate_update_language_box'>Language</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(11)} smooth={true} offset={-320} duration={500} to='candidate_update_hobby_box'>Hobby</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(12)} smooth={true} offset={-320} duration={500} to='candidate_update_reference_box'>Reference</Link>} />
                    <StyledTab label={<Link onClick={() => setValue(13)} smooth={true} offset={-320} duration={500} to='social_box'>Social Profile</Link>} />
                </StyledTabs>
            </Box>
        </Box >
    )
}