import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { postContactInformation } from '../../../fetchAPI'
import SuccessAlert from '../Alerts/SuccessAlert'
import ErrorAlert from '../Alerts/ErrorAlert'

function ContactUs() {

    const [contactData, setContactData] = useState({ source: 'candidate' })

    const [openAlert, setOpenAlert] = useState(false)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')


    const handleChange = (name) => (event) => {
        setContactData({ ...contactData, [name]: event.target.value })
    }

    const sendContactInformation = (body) => (event) => {
        event.preventDefault();
        postContactInformation(body).then((response) => {
            if (response && response.success) {
                setResponseMessage(response.response.message)
                setOpenAlert(true);
            }
            else {
                setResponseMessage(response.errors[0].message);
                setOpenErrorAlert(true);
            }
        })
    }
    return (
        <>
            {/* Contact Us Block */}
            <Card className='atb-card ' style={{ backgroundColor: '#d0efff', marginBottom: '100px' }}>
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <div className="container m-t50">
                            <div className=''>
                                <h1>Contact Us</h1>
                                <p style={{ fontSize: '20px' }}>If you have any queries, feel free to reach out to us.</p>
                            </div>
                            <div className='container m-t50'>
                                <div className='contact-info text-left d-none d-lg-block'>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <img src='/v2/images/location.png' style={{ height: '60px' }} />
                                        </div>
                                        <div className='col-10'>
                                            <p style={{ fontSize: '22px', marginBottom: '55px' }}><span class="">11th Floor, MMK-Aakash Avenue, Plot No. 12, Kamal Ataturk Avenue, Gulshan-2, Dhaka-1212, Bangladesh</span></p>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-2'>
                                            <img src='/v2/images/messege.png' style={{ height: '40px' }} />
                                        </div>
                                        <div className='col-10'>
                                            <p style={{ fontSize: '22px', marginBottom: '55px' }}><span>contact@atb-jobs.com</span></p>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-2'>
                                            <img src='/v2/images/mobile.png' style={{ height: '60px' }} />
                                        </div>
                                        <div className='col-10'>
                                            <p style={{ fontSize: '22px', marginBottom: '55px' }}><span>+8801400492818</span></p>
                                        </div>
                                    </div>

                                </div>
                                <div className='contact-info text-center d-lg-none'>
                                    <div className='row'>
                                        <div className='col-2'>
                                            <img src='/v2/images/location.png' style={{ height: '60px' }} />
                                        </div>
                                        <div className='col-10'>
                                            <p style={{ fontSize: '22px', marginBottom: '55px' }}><span class="">11th Floor, MMK-Aakash Avenue, Plot No. 12, Kamal Ataturk Avenue, Gulshan-2, Dhaka-1212, Bangladesh</span></p>
                                        </div>
                                    </div>
                                    <p style={{ fontSize: '22px', marginBottom: '55px' }}><img src='/v2/images/messege.png' style={{ height: '40px' }} /><span class="ml-3">contact@atb-jobs.com</span></p>
                                    <p style={{ fontSize: '22px', marginBottom: '55px' }}><img src='/v2/images/mobile.png' style={{ height: '60px' }} /><span class="ml-3">+8801400492818</span></p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <img src='/v2/images/placeholder.png' width={'100%'} />
                    </div>
                </div>
            </Card>

            {/* Message Block */}
            <Card className='atb-card ' style={{ backgroundImage: 'url(/v2/images/contactUs-hero.jpg)', backgroundSize: 'cover', marginBottom: '100px' }}>
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <div className='pt-2'>
                            <Card className='atb-card'>
                                <h5 className='mt-3'>Send us a Message</h5>
                                <div className="container">
                                    <div className='text-left'>
                                        <form onSubmit={sendContactInformation(contactData)}>
                                            <div class="form-group">
                                                <input type="text" onChange={handleChange('name')} required class="form-control atb-input-box" placeholder="Enter Your Name" />
                                            </div>
                                            <div class="form-group">
                                                <input type="email" onChange={handleChange('email')} required class="form-control atb-input-box" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email" />
                                            </div>
                                            <div class="form-group">
                                                <textarea type="text" onChange={handleChange('comment')} required class="form-control atb-input-box" id="exampleInputPassword1" placeholder="Message" />
                                            </div>
                                            <div className='btn-lg text-center'>
                                                <button type="submit" class="btn-lg btn btn-primary" style={{ borderRadius: '1rem' }}>SUBMIT</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        {/* No Html here for background image to be seen */}
                    </div>
                </div>
            </Card>
            <SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />
            <ErrorAlert open={openErrorAlert} message={responseMessage} onHide={() => setOpenErrorAlert(false)} />
        </>
    )
}

export default ContactUs